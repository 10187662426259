.edit-model-container {

  .ant-form-item-label,
  .ant-form-item-label label {
    font-size: 12px;
    font-weight: bold;
    color: #6F4B9E;
    text-align: left;
  }

  col {
    width: 9%;
  }

  col:nth-child(2) {
    width: 19%;
  }

  .panel {
    padding-top: 15px;
  }

  .edit-instructions-btn {
    width: min-content;
    margin: 5px;
    margin-top: 15px;
  }

  .ant-form-item {
    padding-bottom: 0;
  }

  .thresholds-panel {
    margin-top: 15px;

    table {
      display: block;
      overflow-x: auto;
      table-layout: fixed;
    }

    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    thead th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    tr td:first-child {
      position: sticky;
      left: 0;
      border-right: 1px solid #f1f1f1;
      z-index: 2;
    }

    .tbl-list-row-0 td:first-child {
      background-color: inherit;
    }

    .tbl-list-row-1 td:first-child {
      background-color: white;
    }

    tr {
      width: 100%;
    }
  }

  .right-panel {
    margin-left: 0px;
    padding-left: 5px;
    padding-bottom: 10px;
    height: fit-content;
  }

  .panels-grid {
    border-block: 2px solid #f1f1f1;
    margin-block: 5px;
  }

  .chairmount-label {
    font-size: 12px;
    font-weight: bold;
    color: #4d4d4d;
    padding-bottom: 8px;
  }

  .ant-upload,
  .ant-upload.ant-upload-drag {
    padding: 10px;
  }

  .ant-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border: 1px solid #D4D4D4;
  border-radius: 5px;
  margin-bottom: 5px;

  img {
    transition: 0.5s all;
    opacity: 1.0;
    max-height: 100%;
    max-width: 100%;
  }

  .loading-icon {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    transition: 0.5s all;
    opacity: 1.0;
    font-size: 40px;
  }

  .delete-button {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 32px;
    height: 32px;
    background-color: #6F4B9E;
    border-radius: 0px 5px 0px 5px;
  }

  .delete-icon {
    border-radius: 0px 5px 0px 5px;
    opacity: 1.0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 600px) {
  .edit-model-container {

    .form-container {
      display: flex;
      flex-direction: column;
      border: 2px solid #efefef;
    }

    #narrow-fields-container {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-row {
      flex: 1 1 18rem;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .edit-model-container {
    .panels-grid {
      display: grid;
      grid-template-columns: 300px auto;
      width: 100%;
    }

    .left-panel {
      border-right: 2px solid #f1f1f1;
      padding-right: 5px;
    }

    .right-panel {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .thresholds-panel,
    .form-item-wide {
      grid-column: 1 / 3;
    }
  }
}

@media screen and (min-width: 1200px) {
  .edit-model-container {
    .panels-grid {
      display: grid;
      grid-template-columns: 300px auto;
      width: 100%;
    }

    .left-panel {
      border-right: 2px solid #f1f1f1;
      padding-right: 5px;
    }

    .right-panel {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .thresholds-panel,
    .form-item-wide {
      grid-column: 1 / 4;
    }
  }
}