table.usageData {
  width: 100%;
}
table.usageData td .cell {
  border-right: 2px solid #efefef;
  margin: 10px;
}
table.usageData td .cell p:first-child {
  font-size: large;
  font-weight: 350;
}
table.usageData td .cell p:last-child {
  font-size: smaller;
  line-height: 10px;
}
table.usageData tr:first-child {
  border-bottom: 2px solid #efefef;
}
table.usageData td:last-child .cell {
  border-right: 0;
}
table.usageData p {
  text-align: center;
}
