.edit-image-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 5px;

  .ant-upload.ant-upload-drag {
    min-width: 50%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  img {
    transition: 0.5s all;
    opacity: 1.0;
    max-height: 100%;
    max-width: 100%;
  }

  .loading-icon {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    transition: 0.5s all;
    opacity: 1.0;
    font-size: 40px;
  }

  .delete-button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    background-color: #6F4B9E;
    border-radius: 0px 5px 0px 5px;
  }

  .delete-icon {
    border-radius: 0px 5px 0px 5px;
    opacity: 1.0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}