.a1-installation-container {

  display: flex;
  flex-direction: row;
  justify-content: center;
  /*align-items: center;*/
  overflow: scroll;

  .a1-error {
    color: red;
  }

  .a1-registration-container {
    margin: 20px;
    max-width: 800px;
    border: none;
    width: 100%;
    display: block;
    flex-direction: column;

    .eqRow {
      align-items: flex-start;

      border: 1px solid #efefef;
      margin: 10px 0px;
      padding: 10px;

      .ant-calendar-picker {
        width: 100%;
      }

      .btnRow {
        margin-left: auto;
      }
    }

    .addLocationButton {
      position: absolute;
      top: -8px;
      right: -32px;
    }

    .equipment-configs-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      gap: 10px;
      flex-wrap: wrap;
      flex-basis: 0;

      .equipment-config-container {
        flex-grow: 1;
        border: 1px solid #efefef;
        margin: 10px 0px;
        padding: 10px;
        min-width: 300px;
      }
    }

  }

  .a1-installation-instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .a1-installation-test-wrapper {
    height: 100vh;
    display: flex;
  }

  .a1-installation-test-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .a1-installation-interactive {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
}