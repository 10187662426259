.edit-instructions-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .ipi-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .ipi {
      width: 40px;
      height: 40px;
      border: 1px solid #D4D4D4;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    .ipi.ipi-active {
      background-color: #6f4b9e;
      color: white;
    }

    .ipi.ipi-inactive {
      background-color: white;
      color: black;
    }

    .ipi-separator {
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      column-gap: 3px;
    }

    .ipi-dot {
      background-color: #D4D4D4;
      width: 6px;
      height: 6px;
      border-radius: 5px;
    }
  }

  .edit-instructions-page {
    border-block: 2px solid #f1f1f1;
    margin-block: 5px;
    margin-top: 10px;
    padding: 2px;
    display: grid;
    column-gap: 10px;
  }

  @media screen and (min-width: 750px) {
    .edit-instructions-page {
      grid-template-columns: 2fr 3fr;
    }
  }

  .edit-images-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    max-height: 90vh;

    .ant-upload.ant-upload-drag {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }

    .edit-image-container {
      /*
      width: 40vh;
      height: 40vh;
      */
      aspect-ratio: 1;
    }
  }


}