.panel-title {
  color: #6f4b9e;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.manage-model-container {
  .panels-grid {
    display: grid;
    border-top: 2px solid #f1f1f1;
  }

  .image-panel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media screen and (max-width: 749px) {
    .info-panel {
      border-top: 2px solid #f1f1f1;
    }
  }

  @media screen and (min-width: 750px) {
    .panels-grid {
      grid-template-columns: fit-content(200px) auto;
      width: 100%;
    }

    .image-panel {
      padding-top: 0.5rem;
      padding-right: 10px;
    }

    .left-panels {
      border-right: 2px solid #f1f1f1;
    }

    .left-panels:empty {
      border-right: none;
    }

    .right-panels {
      margin-left: 0px;
      padding-left: 10px;
    }
  }

  .right-panels {
    max-width: 100%;
    min-width: 0;
  }

  .panel {
    margin-bottom: 10px;
  }

  .image-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  .info-panel {
    color: #727272;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .row-name {
      font-weight: 700;
      margin-right: 5px;
    }
  }

  .instructions-panel {
    position: relative;
    margin-left: auto;

    border-top: 2px solid #f1f1f1;
    min-height: 32px;

    padding-bottom: 1.5rem;
    padding-top: 0.5rem;

    button.ant-btn {
      position: absolute;
      right: 0;
      top: 0.5rem;
    }

    table {
      margin-top: 8px;

      th,
      td {
        padding: 5px;
      }
    }

    .instructions-page-title {
      font-weight: 400;
      font-size: 14px;
    }

    .instructions-page-check {
      text-align: center;

      .anticon {
        width: 20px;
        height: 20px;
        font-size: 18px;
        margin-inline: auto;
      }

      .anticon-exclamation-circle {
        color: #cc1c1b;
      }

      .anticon-check-circle {
        color: #62c71c;
      }

      .anticon-edit {
        color: #424242;
      }
    }
  }

  .thresholds-panel {
    padding-top: 0.5rem;
    border-top: 2px solid #f1f1f1;

    col {
      width: 9%;
    }

    col:nth-child(2) {
      width: 19%;
    }

    table {
      display: block;
      overflow-x: auto;
      table-layout: fixed;
    }

    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    thead th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    tr td:first-child {
      position: sticky;
      left: 0;
      border-right: 1px solid #f1f1f1;
      z-index: 2;
    }

    .tbl-list-row-0 td:first-child {
      background-color: inherit;
    }

    .tbl-list-row-1 td:first-child {
      background-color: white;
    }
  }
}